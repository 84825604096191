<template>
<div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <div>
            <div class="p-0">
                <div class="pos__tabs nav nav-tabs justify-between" role="tablist">
                    <h2 class="text-lg font-medium mr-auto">Release Stock</h2>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-12 gap-x-2 mt-5 p-2" v-if="page_data.invoice_details">
          <div class="col-span-6 md:col-span-4 flex-col">
            <div class="mt-2">Invoice No: {{ page_data.invoice_details.invoice_no }}</div>
            <div class="mt-2">Customer: {{ page_data.invoice_details.company_name }}</div>
            <div class="mt-2">Sell Date: {{ page_data.invoice_details.sell_date }}</div>
            <div class="mt-2">Entry Date: {{ page_data.invoice_details.created_at }}</div>
          </div>
          <div class="col-span-6 md:col-span-4 flex-col">
            <div class="mt-2">Quantity: {{ page_data.invoice_details.quantity }}</div>
            <div class="mt-2">Total Boxes: {{ page_data.invoice_details.number_of_boxes }}</div>
            <div class="mt-2">Customer Received QTY: {{ page_data.invoice_details.quantity_customer_received }}</div>
            <div class="mt-2">Dispatch Delivery Date: {{ page_data.invoice_details.dispatch_delivery_date }}</div>
          </div>
        </div>
        <div class="grid grid-cols-12 gap-x-2 mt-5 border-t-2 py-5 align-top" v-if="page_data.invoice_details">
            <div class="col-span-6 mt-5 grid grid-cols-12">
              <div class="col-span-12">
                  <div class="p-0">
                      <div class="pos__tabs nav nav-tabs justify-between" role="tablist">
                          <h2 class="text-lg font-medium mr-auto">Attachments</h2>
                      </div>
                  </div>
              </div>
              <div class="col-span-12 mt-2">
                <div class="flex items-center justify-start">

                  <div class="text-md font-bold">Packing List:</div>
                  <span  class="ml-2">
                    <div v-if="page_data.invoice_details.packing_list_url">
                      <a :href="page_data.invoice_details.packing_list_url" target="_blank" class="text-primary-1 underline">{{ page_data.invoice_details.packing_list }}</a>
                    </div>
                    <div v-else>
                      <!-- <a href="javascript:;" class="text-primary-1 underline" @click="file_modal('packing_list', 'packing_list_url')">Click here to upload</a> -->

                    </div>
                  </span>
                </div>
              </div>
              <div class="col-span-12 mt-2">
                <div class="flex items-center justify-start">

                  <div class="text-md font-bold">COA Details:</div>
                  <span  class="ml-2">
                    <div v-if="page_data.invoice_details.coa_url">
                      <a :href="page_data.invoice_details.coa_url" target="_blank" class="text-primary-1 underline">{{ page_data.invoice_details.coa }}</a>
                    </div>
                    <div v-else>
                      <!-- <a href="javascript:;" class="text-primary-1 underline" @click="file_modal('coa', 'coa_url')">Click here to upload</a> -->

                    </div>
                  </span>
                </div>
              </div>
              <div class="col-span-12 mt-2">
                <div class="flex items-center justify-start">

                  <div class="text-md font-bold">Tracking Details:</div>
                  <span  class="ml-2">
                    <div v-if="page_data.invoice_details.tracking_details_url">
                      <a :href="page_data.invoice_details.tracking_details_url" target="_blank" class="text-primary-1 underline">{{ page_data.invoice_details.tracking_details }}</a>
                    </div>
                    <div v-else>
                      <!-- <a href="javascript:;" class="text-primary-1 underline" @click="file_modal('tracking_details', 'tracking_details_url')">Click here to upload</a> -->

                    </div>
                  </span>
                </div>
              </div>
              <div class="col-span-12 mt-2">
                <div class="flex items-center justify-start">

                  <div class="text-md font-bold">LR Details:</div>
                  <span  class="ml-2">
                    <div v-if="page_data.invoice_details.lr_url">
                      <a :href="page_data.invoice_details.lr_url" target="_blank" class="text-primary-1 underline">{{ page_data.invoice_details.lr }}</a>
                    </div>
                    <div v-else>
                      <!-- <a href="javascript:;" class="text-primary-1 underline" @click="file_modal('lr', 'lr_url')">Click here to upload</a> -->

                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-span-6 mt-5 grid grid-cols-12 align-top">
              <div class="col-span-12">
                  <div class="p-0">
                      <div class="pos__tabs nav nav-tabs justify-between" role="tablist">
                          <h2 class="text-lg font-medium mr-auto">IMAGES</h2>
                      </div>
                  </div>
              </div>
              <div class="col-span-12 mt-2" v-for="(image,index) in page_data.invoice_details.dispatch_images" :key="index">
                <div class="flex items-center justify-start">
                  <span  class="ml-2">
                    <div v-if="image.url">
                      {{ index + 1 }}
                      <a :href="image.url" target="_blank" class="text-primary-1 underline">{{ image.name }}</a>
                    </div>
                  </span>
                </div>
              </div>
              <div class="col-span-12 mt-2">
                <div class="flex items-center justify-start">
                  <span  class="ml-2">
                    <!-- <a href="javascript:;" class="text-primary-1 underline" @click="image_modal" v-if="page_data.invoice_details.dispatch_images && page_data.invoice_details.dispatch_images.length == 0">+ Add Images</a>
                    <a href="javascript:;" class="text-primary-1 underline" @click="image_modal" v-else>+ Add More Images</a> -->
                  </span>
                </div>
              </div>
            </div>
        </div>

        <div class="intro-y col-span-12 p-5 px-0 border-t-2" v-if="page_data.invoice_details">
            <div class="intro-y">
                <h2 class="font-medium text-lg mr-auto">Release Modules Emteries</h2>
                <div class="my-6 shadow">

                    <table class="w-full border mt-3">
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Product</th>
                                <th>Code</th>
                                <th>Type</th>
                                <th>Request Code</th>
                                <th>Release Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border-b" v-for="(plan,index) in page_data.invoice_details.pow_details" :key="index">
                                <td>{{ index + 1 }}</td>
                                <td class="text-center">{{ plan.watt.watt }}</td>
                                <td class="text-center">{{ plan.watt.code }}</td>
                                <td class="text-center">{{ plan.product_type == 1 ? "Poly" : "MBB" }}</td>
                                <td class="text-center">{{ plan.module.request_code }}</td>
                                <td class="text-center">{{ plan.quantity }}</td>
                            </tr>
                        </tbody>

                    </table>

                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
/* eslint-disable */
import {
    defineComponent,
    reactive, ref,
    onMounted,
    inject,
} from 'vue'
import axios from 'axios'
import {
    BASE_URL
} from '@/constant/config.js'
import Dropzone from 'dropzone'
import cash from 'cash-dom'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
    setup() {
      const route = useRoute();
      const page_data = reactive({
        base_url: BASE_URL,
        invoice_details: []
      })
      const get_inovice_details = (id) => {

        let promise_url = "api/get_sell_item";
        let params = {
          id: id
        }

        let promise = axios({
          url: promise_url,
          method: "POST",
          data: params,
          baseURL: BASE_URL,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          config: { headers: { Accept: "application/json" } }
        });
        return promise
        .then(result => result.data)
        .then(data => {
          page_data.invoice_details = data.item
        })
        .catch(_error => {
          page_data.invoice_details = []

        });

      }
      onMounted(() => {
        get_inovice_details(route.params.id)
      })

      return {
          page_data
      }
    }
})
</script>

<style scoped>
.tableFixHead {
    overflow-y: auto;
    /* make the table scrollable if height is more than 200 px  */
    max-height: 700px;
    /* gives an initial height of 200px to the table */
}

.tableFixHead thead {
    position: sticky;
    /* make the table heads sticky */
    top: 0px;
    /* table head will be placed from the top of the table and sticks to it */
}
</style>
